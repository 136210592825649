import styled from 'styled-components';

const RegularFont = 'Rubik';
const MediumFont = 'Rubik-Medium';

const MainContainer = styled.div`
float: left;
margin-bottom: 25px;
width: 100%;
margin-right: 15px;
margin-top: 14px;
height: 100%;

  > div: first-child {
    text-transform: uppercase;
    font-size: 18px;
    color: #0D4270;
    font-family: ${MediumFont};
  }
  @media(max-width: 1200px) {
		width: 100%;
		margin-right: 0px;
  }
  @media (max-width: 766px) {
    width: 100%;
  }

`;
const SubContainer = styled.div`
width: 100%;
height: 140px;
background-color: #FFF;
margin-top: 15px;
border-radius: 4px;
box-shadow: 0 2px 2px rgb(0 0 0 / 19%), 0 4px 6px rgb(0 0 0 / 7%);

> div: first-child {
  padding: 5px;
  width: 100%;
  height: 70%;
	background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

> div: last-child {
  width: 100%;
  height: 30%;
  padding: 7px;
  display: flex;
  justify-content: space-between;
}
`;
const ImageContainer = styled.div`
width: 25%;
height: 100%;
padding: 10px;
float: left;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;
const TextContainer = styled.div`
width: 75%;
height: 100%;
padding: 10px;
float: right;
display: flex;
justify-content: space-evenly;
flex-flow: column;

  > span: first-child {
    text-transform: uppercase;
    font-size: 12px;
    color: #A8FFC5;
    font-family: ${RegularFont};
  }
  > p {
    text-transform: uppercase;
    font-size: 15px;
    color: #FFF;
    font-family: ${MediumFont};
  }
  > div {
    width: 100%;
    display: flex;

    > span {
    width: 20%;
    font-size: 10px;
    color: #006FBC;
    font-family: ${RegularFont};
    padding-left: 5px;
    margin-top: -3px;
    }
  }
`;
const ProgressBar = styled.div`
  width: 80%;
  height: 33%;
  height: 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.17);
  overflow: hidden;
  .progrss {
    height: 8px;
    border-radius: 9px;
    background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
    width: ${({width}) => width && `${width}%`};
  }
`;
const DayToStart = styled.div`
font-size: 10px;
color: #0D4270;
font-family: ${RegularFont}
padding-top: 7px;
`;
const DateContainer = styled.div`
font-size: 12px;
color: #0D4270;
font-family: ${MediumFont}
padding-top: 7px;

	@media(max-width: 345px) {
		padding-top: 0px;
	}
`;
const IconContainer = styled.div`
display: flex;
margin-left: 0px;
padding-left: 5px;

  > span {
    margin-left: -12px;

    > img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid rgb(255, 255, 255);
    }
  }

  .circle {
    font-family: ${MediumFont}
    font-size: 11px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    text-align: center;
    background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
  }
`;

const Live = styled.div`
width: calc(100% - 25px);
margin-right: 25px;
margin-bottom: 25px
`;

const LiveEventTitle = styled.div`
width: 100%;

font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 21px;
color: #0D4270;
`;

const LiveEventContainer = styled.div`
width: 100%;
display: flex;
background: white;
border-radius: 6px;
margin-top: 15px;
> div: first-child {
  width: 80%;
  display: flex;
  cursor: pointer;

  >div: first-child {
    width: 191px;
    height: 120px;
    margin-right: 15px;
    >img{
      width: 100%;
      height: 100%;
    }
  }
  >div: last-child {
    width: calc(100% - 206px);
    >div:nth-child(1){
      width: 100%;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 19px;
      color: #0D4270;
      margin-top: 25px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    >div:nth-child(2){
      margin: 9px 0px; 
      width: 100%;
      display: flex;
      >div:nth-child(1){
      font-family: 'Rubik-Regular';
      font-size: 14px;
      line-height: 17px;
      color: #0D4270;
    }
    >div:nth-child(2){
      font-family: 'Rubik-Medium';
      font-size: 14px;
      line-height: 17px;
      color: #F6B479;
    }

    }
    >div:nth-child(3){
      width: 100%;
      font-family: 'Rubik-Regular';
      font-size: 14px;
      line-height: 17px;
      color: #0D4270;
    }
    
  }
}
> div: last-child {
  width: 20%;
  display: flex;
  max-width: 165px;
  >button{
    width: calc(100% - 15px);
    height: 40px;
    background: #69C2FF;
    background: ${({background}) => background ? background : '#69C2FF'};

    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    align-items: center;
    display: flex;
    margin: auto;
    justify-content: center;
    border: none;
    border-radius: 6px;
    cursor: ${({cursor}) => cursor ? cursor : 'pointer'};
    margin-left: 0px;
    >a{
      text-decoration: none;
      color: white;
      width: 100%;
      display: flex;
      justify-content: center;
      cursor: ${({cursor}) => cursor ? cursor : 'pointer'};
    }
  }
}
`;

export {
  MainContainer, SubContainer, ImageContainer, TextContainer, ProgressBar, DayToStart, DateContainer, IconContainer, /*MainContainerNew,*/ Live, LiveEventTitle, LiveEventContainer
}