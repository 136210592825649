/* eslint-disable no-console,no-undef */
import React from 'react';
// import { MainContainer, SubContainer, ImageContainer, TextContainer, ProgressBar, DayToStart, DateContainer, IconContainer, MainContainerNew } from './styles';
import {
  convertDateInTimezone, getTimeToShow
} from "../../../utils/methods";
import PropTypes from 'prop-types';
import moment from "moment";
import { ImageUrl } from '../../../utils/constants';
import { /*isEmpty,*/ isNull, isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { getMyUpcomingEvents } from '../../../redux/actions';
import { LoaderContainer } from "../../Content/styles";
import Loading from '../../Loading';
// import { Img } from "react-image";
import { MainContainer, SubContainer, ImageContainer, TextContainer, IconRight, HeadingMain } from '../UpComingChallenges/styles';

class UpComingEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeArray: []
    }
  }

  componentDidMount() {
    const { upComingEvent, getMyUpcomingEvents } = this.props;
    getMyUpcomingEvents();
    if (upComingEvent) {
      if (upComingEvent && upComingEvent.length > 0) {
        this.interval = setInterval(() => {
          this.setState({
            timeArray: getTimeToShow(upComingEvent[0], 'event')
          })
        }, 1000);
      }
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.upComingEvent !== this.props.upComingEvent) {
  //     clearInterval(this.interval);
  //     if (!isNull(this.props.upComingEvent) && this.props.upComingEvent.length > 0 && !isNull(this.props.upComingEvent[0]) && !isNull(this.props.upComingEvent[0].end_date) && !isNull(this.props.upComingEvent[0].start_date)) {
  //       this.interval = setInterval(() => {
  //         this.setState({
  //           timeArray: getTimeToShow(this.props.upComingEvent[0], 'event')
  //         })
  //       }, 1000);
  //     }
  //   }
  // }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { upComingEvent, history, padding } = this.props;
    let data = [];
    if ((upComingEvent) && upComingEvent && upComingEvent.length > 0) {
      data = upComingEvent[0];
    }
    return (
      <div>
        {data && data.length === 0 ? null :
          isUndefined(upComingEvent) || isNull(upComingEvent) ?
            <LoaderContainer><Loading /></LoaderContainer> :
            <MainContainer paddingValue={padding === 1 ? '25px 25px 0px 0px' : '0px 0 15px 0'}>
              <HeadingMain>
                <div>
                  <div>My Active Events</div>
                </div>
              </HeadingMain>
              {upComingEvent.map((activeEvent, index) => (
                <SubContainer onClick={() => history.push(`/events/${activeEvent.id}`)} background={"1"} key={index}>
                  <div>
                    <ImageContainer>
                      <img src={`${ImageUrl}/${activeEvent.image}`} />
                    </ImageContainer>
                    <TextContainer color={"fff"} background={"1"}>
                      <span style={{ "font-size": "14px" }}>Featured Event</span>
                      <p style={{ "margin": "0px" }}>{activeEvent && activeEvent.title}</p>
                      <p style={{ "font-size": "14px", "margin": "0px" }}>{moment(convertDateInTimezone(activeEvent && activeEvent.start_date)._d).format('MMM DD, YYYY')}</p>
                    </TextContainer>
                    <IconRight background={"1"}>
                      <img src="/public/images/NewDashboardV2/arrowRight.png" alt="icon2" width="7px" height="16px" />
                    </IconRight>
                  </div>
                </SubContainer>))}

            </MainContainer>
        }
      </div>
    )
  }
}
UpComingEvents.propTypes = {
  history: PropTypes.object.isRequired,
  upComingEvent: PropTypes.array,
  getMyUpcomingEvents: PropTypes.func,
  padding: PropTypes.string
};
const mapStateToProps = (state) => ({
  upComingEvent: state.profileData.myUpcomingEvents
})
const mapDispatchToProps = (dispatch) => ({
  getMyUpcomingEvents: () => dispatch(getMyUpcomingEvents()),
})
export default connect(mapStateToProps, mapDispatchToProps)(UpComingEvents);
