/* eslint-disable no-console,no-undef */
import React from 'react';
import { convertDateInTimezone, getChallengeTimeToShow } from "../../../utils/methods";
import PropTypes from 'prop-types';
import moment from "moment";
import { ImageUrl } from '../../../utils/constants';
import { ImageContainerNew, SubContainerNew, TextContainerNew} from '../UpComingChallenges/styles';
import momentTZ from 'moment-timezone';
import { withTranslation } from 'react-i18next';

class UpComingEventsSocialSub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: []
    }
  }

  componentDidMount() {
    const { data } = this.props; {
      this.setState({
        time: getChallengeTimeToShow(data)
      })
    }
  }

  render() {
    const { data, history, t } = this.props;
    const { time } = this.state;
    const DaysLeft = time.length > 0 && time.map((step) => step);
    const lang = localStorage.getItem("lang");
    return (
      <SubContainerNew onClick={() => history.push(`/events/${data.id}`)} background={"1"} height={"104px"}>
        <div>
          <ImageContainerNew>
            <img src={`${ImageUrl}/${data.image}`} />
          </ImageContainerNew>
          <TextContainerNew color={"fff"} background={"1"}>
            <p>{data && data.title}</p>
          </TextContainerNew>
        </div>
        <div>
          <div>
            <span>{t("Start Date")} : { (lang !== "fr") ? (moment(convertDateInTimezone(data && data.start_date)._d).format('MMM DD, YYYY')) : (moment(convertDateInTimezone(data && data.start_date)._d).format('DD MMM YYYY'))}</span>
          </div>
          {data.is_completed === 1 ? null :
            <div className="days">
              <span>{(DaysLeft === 'Starts Today' || DaysLeft === 'Last Day') ? DaysLeft : (convertDateInTimezone(data.start_date)._d <= momentTZ()._d ? `${DaysLeft} ${t("Remaining")}` : `${DaysLeft} ${t("To Start")}`)}</span>
            </div>}
        </div>
      </SubContainerNew>
    )
  }
}
UpComingEventsSocialSub.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.array,
  t: PropTypes.func
};

export default (withTranslation()(UpComingEventsSocialSub));

