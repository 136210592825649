/* eslint-disable no-console,no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import {isNull, isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { getUpcomingEvents } from '../../../redux/actions';
import { LoaderContainer } from "../../Content/styles";
import Loading from '../../Loading';
import { MainContainerNew } from '../UpComingChallenges/styles';
import UpComingEventsSocialSub from './eventSocialFeedSub';
import { withTranslation } from 'react-i18next';

class UpComingEventsSocial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeArray: [],
      time: []
    }
  }

  componentDidMount() {
    const { getUpcomingEvents } = this.props;
    getUpcomingEvents();
  }

  render() {
    const { upComingEvent, history, padding, t } = this.props;
    let data = [];
    if ((upComingEvent) && upComingEvent && upComingEvent.length > 0) {
      data = upComingEvent[0];
    }
    return (
      <div>
        {data && data.length === 0 ? null :
          isUndefined(upComingEvent) || isNull(upComingEvent) ?
            <LoaderContainer><Loading /></LoaderContainer> :
            <MainContainerNew paddingValue={padding === 1 ? '25px 25px 0px 0px' : padding === 3 ? '0px 0 25px 0' : '0px 0 25px 0'}>
              <div>
                <div>{t("Upcoming Events")}</div>
                {upComingEvent && upComingEvent.length > 1 &&
                  <div onClick={() => history.push('/events')}>
                    {t("See All >")}
                  </div>}
              </div>
              <UpComingEventsSocialSub data={data} history={history}/>
            </MainContainerNew>
        }
      </div>
    )
  }
}
UpComingEventsSocial.propTypes = {
  history: PropTypes.object.isRequired,
  upComingEvent: PropTypes.object,
  getUpcomingEvents: PropTypes.func,
  padding: PropTypes.string,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  upComingEvent: state.profileData.upComingEvent
})
const mapDispatchToProps = (dispatch) => ({
  getUpcomingEvents: () => dispatch(getUpcomingEvents()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpComingEventsSocial));

