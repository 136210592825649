/* eslint-disable no-console,no-undef, react/no-unknown-property */
import React from 'react';
import { Live, LiveEventTitle, LiveEventContainer } from './styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLiveEvents, attendEventV4 } from '../../../redux/actions';
import { isNull, isUndefined } from 'lodash';
// import { LoaderContainer } from "../../Content/styles";
import { ImageUrl } from '../../../utils/constants';
import moment from "moment";
import { convertDateInTimezone, convertMonthFromDate} from "../../../utils/methods";
import { withTranslation } from 'react-i18next';
import SkeletonLoder from '../../common/skeletonLoder';

class LiveEvents extends React.Component {

  componentDidMount() {
    const { getLiveEvents } = this.props;
    getLiveEvents();
  }

  handleEventAttending = (eventId, isAttending) => {
    const { attendEvent} = this.props;
    attendEvent(eventId, isAttending, 'events');
  };

  calculateMinuits = (targetDate) => {
    let parsedDate1 = new Date(targetDate);
    let parsedDate2 = new Date();
    let difference = (difference = Math.abs(parsedDate2 - parsedDate1));
    if (difference <= 0) {
      return "Target date has already passed";
    }
    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let showButton = days===0 && hours===0 && (minutes>0&&minutes<31) ?true: false;
    return showButton;
  };

  render() {
    const { liveEvents, history, t } = this.props;
    let data = [];
    if (liveEvents && liveEvents.length > 0) {
      data = liveEvents[0];
    }
    let currentDate = new Date();
    const lang = localStorage.getItem("lang");

    return (
      <React.Fragment>
        {data && data.length == 0 ? null :
          isUndefined(liveEvents) || isNull(liveEvents) ?
            <SkeletonLoder width={"835px"} height={"120px"}/> :
            <Live>
              <LiveEventTitle>{t("Today's Live Event")}</LiveEventTitle>
              {liveEvents.map((event, index) => (
                <LiveEventContainer key={index} background={currentDate > new Date(moment(convertDateInTimezone(event.start_date)._d))?"#69c2ff": !event.is_attending?"#69c2ff": event.event_location && (event.is_attending||!event.is_attending) ?"#69c2ff":!event.event_location && event.is_attending===1 &&this.calculateMinuits(moment(convertDateInTimezone(event && event.start_date)._d))?"#69c2ff":"rgba(105, 194, 255, 0.5)"}
                  cursor={currentDate > new Date(moment(convertDateInTimezone(event.start_date)._d))?"#69c2ff": !event.is_attending?"pointer":event.event_location && (event.is_attending||!event.is_attending)? "pointer": event.event_location && event.is_attending===1? "pointer": !event.event_location && event.is_attending===1 &&this.calculateMinuits(moment(convertDateInTimezone(event && event.start_date)._d))?"pointer":"not-allowed"}
                >
                  <div   onClick={() => history.push(`/events/${event.id}`)} >
                    <div>
                      <img src={`${ImageUrl}/${event.image}`} />
                    </div>
                    <div>
                      <div>{event.title}</div>
                      <div>
                        <div>{t("Date")}: { (lang !== 'fr') ? ( convertMonthFromDate(moment(convertDateInTimezone(event && event.start_date)._d).format('MMM DD, YYYY'), this.props)) : ( convertMonthFromDate(moment(convertDateInTimezone(event && event.start_date)._d).format('DD MMM YYYY'), this.props)) } - { (lang !== 'fr') ? (convertMonthFromDate(moment(convertDateInTimezone(event && event.end_date)._d).format('MMM DD, YYYY'), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(event && event.end_date)._d).format('DD MMM YYYY'), this.props)) } |</div>&nbsp;
                        <div>{event.event_location?t("Onsite Event"): t("Virtual Event")}</div>
                      </div>
                      <div>{t("Time")}: {moment(convertDateInTimezone(event.start_date)._d).format('hh:mm a')} - {moment(convertDateInTimezone(event.end_date)._d).format('hh:mm a')} ({(event.abbreviation)})</div>
                    </div>
                  </div>
                  <div>
                    {currentDate > new Date(moment(convertDateInTimezone(event.start_date)._d))?
                      (event.event_location?<button onClick={() => history.push(`/events/${event.id}`)}>{t("Launch Event")}</button>:<button a href={event.event_encrypted_link} target="_blank" rel="noreferrer"><a href={event.event_encrypted_link} target="_blank" rel="noreferrer">{t("Launch Event")}</a></button>) :
                      this.calculateMinuits(moment(convertDateInTimezone(event && event.start_date)._d))&&!event.event_location?<button a href={event.event_encrypted_link} target="_blank" rel="noreferrer"><a href={event.event_encrypted_link} target="_blank" rel="noreferrer">{t("Launch Event")}</a></button>:
                        event.is_attending===1?(event.event_location?<button onClick={() => history.push(`/events/${event.id}`)}>{t("Launch Event")}</button>:
                          !event.event_location && event.is_attending===1 &&this.calculateMinuits(moment(convertDateInTimezone(event && event.start_date)._d))?<button a href={event.event_encrypted_link} target="_blank" rel="noreferrer"><a href={event.event_encrypted_link} target="_blank" rel="noreferrer">{t("Launch Event")}</a></button>:<button>{t("Launch Event")}</button>
                        ):
                          <button onClick={() => this.handleEventAttending(event.id, event.is_attending)}>{t("RSVP")}</button>}
                  </div>
                </LiveEventContainer>))}
            </Live>}
      </React.Fragment>
    )
  }
}

LiveEvents.propTypes = {
  history: PropTypes.object.isRequired,
  liveEvents: PropTypes.array,
  getLiveEvents: PropTypes.func,
  attendEvent: PropTypes.func.isRequired,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  liveEvents: state.profileData.liveEvents
})
const mapDispatchToProps = (dispatch) => ({
  getLiveEvents: () => dispatch(getLiveEvents()),
  attendEvent: (eventId, isAttending, updationList) => dispatch(attendEventV4(eventId, isAttending, updationList))
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LiveEvents));
